<script>
import { ref } from 'vue';
export default {
    setup() {
        const isOpen = ref(false);

        return {
            isOpen,
        };
    },
}
</script>

<template>
<!-- This example requires Tailwind CSS v2.0+ -->
<nav class="bg-gray-800">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div class="relative flex items-center justify-between h-16">
            <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <!-- Mobile menu button-->
                <button @click="isOpen = !isOpen" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-expanded="false">
                    <span class="sr-only">Open main menu</span>
                    <!-- Icon when menu is closed. -->
                    <!--
                    Heroicon name: menu

                    Menu open: "hidden", Menu closed: "block"
                    -->
                    <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    <!-- Icon when menu is open. -->
                    <!--
                    Heroicon name: x

                    Menu open: "block", Menu closed: "hidden"
                    -->
                    <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div class="flex-shrink-0 flex items-center">
                    <img class="mr-2 h-8 w-8" src="@/assets/logo.png" alt="">
                    <span class="text-white">Radius Map</span>
                </div>
                <div class="hidden sm:block sm:ml-6">
                    <div class="flex space-x-4">
                    <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                    <router-link to="/" :class="[{'bg-gray-900 text-white' : this.$route.name === 'Home'}, {'text-gray-300 hover:bg-gray-700 hover:text-white' : this.$route.name !== 'Home'}, 'px-3 py-2 rounded-md text-sm font-medium']">Home</router-link>
                    <router-link to="/about" :class="[{'bg-gray-900 text-white' : this.$route.name === 'About'}, {'text-gray-300 hover:bg-gray-700 hover:text-white' : this.$route.name !== 'About'}, 'px-3 py-2 rounded-md text-sm font-medium']">About</router-link>
                    </div>
                </div>
            </div>
            <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"></div>
        </div>
    </div>

    <!--
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
    -->
    <div :class="isOpen ? 'block' : 'hidden'" class="sm:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1">
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <router-link to="/" :class="[{'bg-gray-900 text-white' : this.$route.name === 'Home'}, {'text-gray-300 hover:bg-gray-700 hover:text-white' : this.$route.name !== 'Home' }, 'block px-3 py-2 rounded-md text-base font-medium']">Home</router-link>
            <router-link to="/about" :class="[{'bg-gray-900 text-white' : this.$route.name === 'About'}, {'text-gray-300 hover:bg-gray-700 hover:text-white' : this.$route.name !== 'About' }, 'block px-3 py-2 rounded-md text-base font-medium']">About</router-link>
        </div>
    </div>
</nav>
</template>

<style>

</style>