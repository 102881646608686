<script>
export default {}
</script>

<template>
    <footer class='bg-gray-800 text-white w-full text-center border-t border-grey p-2 pin-b'>
        <p class="sm:text-sm text-xs">Developed by <a href="https://github.com/coderkk" class="text-blue-300 hover:underline hover:text-grey-500">coderkk</a>. Check <a href="https://github.com/coderkk/radiusmap" class="text-blue-300 hover:underline hover:text-grey-500">source code</a> here.</p>
    </footer>
</template>

<style></style>